import { DateTime } from 'luxon'
import { AnalyticsService } from 'src/services/analytics/analytics.service'
import { Calendar } from 'src/services/calendar/calendar.types'
import { CalendarEventAPI } from 'src/services/calendar/calendar-event.api'
import { EndUserProps } from 'src/services/user/user.types'
import { ValidationResult } from 'src/services/validation/validation.types'

export function EditCalendarEventService() {
	/** Receives a start date and start time in UTC, returning the same date and time localized to the users time zone */
	function convertUTCDateToLocal(props: {
		/** Date, formatted as YYYY-MM-DD */
		utcDate: string
		/** Time, formatted as HH:MM:SS */
		utcTime: string
	}): { localDate: string; localTime: string } | null {
		const dateISOString = `${props.utcDate}T${props.utcTime}Z`
		const localizedDateObj = DateTime.fromISO(dateISOString).toLocal()
		let localizedDateString = localizedDateObj.toISODate()
		const localizedTimeString = localizedDateObj.toISOTime({ includeOffset: false })

		if (!localizedDateString || !localizedTimeString) {
			return null
		}

		return {
			localDate: localizedDateString,
			localTime: localizedTimeString,
		}
	}

	function convertLocalDateToUTC(props: {
		/** Date, formatted as YYYY-MM-DD */
		localDate: string
		/** Time, formatted as HH:MM:SS */
		localTime: string
	}): { utcDate: string; utcTime: string } | null {
		const dateISOString = `${props.localDate}T${props.localTime}`
		const utcDateObj = DateTime.fromISO(dateISOString).toUTC()

		let utcDateString = utcDateObj.toISODate()
		const utcTimeString = utcDateObj.toISOTime({ includeOffset: false })

		if (!utcDateString || !utcTimeString) {
			return null
		}

		return {
			utcDate: utcDateString,
			utcTime: utcTimeString,
		}
	}

	function getNewEventProps(props: { user: EndUserProps; calendar: Calendar.Calendar }): Calendar.Event {
		let currentDateObj = DateTime.now().toUTC()

		// Reset time to beginning of an hour
		currentDateObj = currentDateObj.set({ minute: 0 })

		/** Get event default event start and end time */
		const startHours = currentDateObj.hour.toString().padStart(2, '0')
		const endHours = (currentDateObj.hour + 1).toString().padStart(2, '0')

		return {
			calendarEventId: -1,
			title: '',
			allDay: false,
			startDate: currentDateObj.toISODate(),
			endDate: currentDateObj.toISODate(),
			startTime: `${startHours}:00`,
			endTime: `${endHours}:00`,
			description: '',
			recurring: false,
			frequency: 'WEEKLY',
			recurranceInterval: 1,
			days: [],
			hasEndDate: false,
			licensedStates: [],
			tags: [],
			createdByEndUser: props.user,
			calendar: props.calendar,
			recurrenceCount: null,
			recurrenceEndDate: null,
		}
	}

	function validate(event: Calendar.Event): ValidationResult {
		let result: ValidationResult = {
			isValid: true,
			messages: [],
		}

		if (!event.title || (typeof event.title === 'string' && event.title.length === 0)) {
			result.isValid = false
			result.messages.push(`Title cannot be empty`)
		}

		if (!event.calendar?.calendarId) {
			result.isValid = false
			result.messages.push(`You must select a calendar`)
		}

		if (!event.description || (typeof event.description === 'string' && event.description.length === 0)) {
			result.isValid = false
			result.messages.push(`Description cannot be empty`)
		}

		if (!event.licensedStates || event.licensedStates.length === 0) {
			result.isValid = false
			result.messages.push('You must select a state')
		}

		if (!event.tags || event.tags.length === 0) {
			result.isValid = false
			result.messages.push('You must select at least one tag')
		}

		/** Make sure event has start / end dates */
		if (!event.startDate || (typeof event.startDate === 'string' && event.startDate.length === 0)) {
			result.isValid = false
			result.messages.push(`Start date cannot be empty`)
		}
		if (!event.endDate || (typeof event.endDate === 'string' && event.endDate.length === 0)) {
			result.isValid = false
			result.messages.push(`End date cannot be empty`)
		}

		/** Make sure event has start / end times */
		if (!event.startTime) {
			result.isValid = false
			result.messages.push(`Start time cannot be empty`)
		}
		if (!event.endTime) {
			result.isValid = false
			result.messages.push(`End time cannot be empty`)
		}

		return result
	}

	function createCalendarEvent(event: Calendar.Event): Promise<Calendar.Event> {
		return new Promise((resolve) => {
			CalendarEventAPI.createEvent(event).then((newCalendarEventRes) => {
				AnalyticsService.pushEvent({
					event_category: 'change',
					event_label: 'create_calendar_event',
					value: {
						calendar_event_id: newCalendarEventRes.data.calendarEventId,
						calendar_event_title: newCalendarEventRes.data.title,
					},
				})
				resolve(newCalendarEventRes.data)
			})
		})
	}

	function updateCalendarEvent(event: Calendar.Event): Promise<Calendar.Event> {
		return new Promise((resolve) => {
			const strippedLicenseInformation = event.createdByEndUser.licenseInformation.map(
				({ licenseAgreement, ...rest }) => rest,
			)

			// Stripping licenseAgreemnt from licenseInformation in 'adjustedEvent' for end point
			const strippedEvent = {
				...event,
				createdByEndUser: {
					...event.createdByEndUser,
					licenseInformation: strippedLicenseInformation,
				},
			}

			CalendarEventAPI.updateEvent(event.calendarEventId, strippedEvent as Partial<Calendar.Event>).then(
				(updatedCalendarEventRes) => {
					resolve(updatedCalendarEventRes.data)
				},
			)
		})
	}

	return {
		getNewEventProps,
		createCalendarEvent,
		updateCalendarEvent,
		validate,
		convertUTCDateToLocal,
		convertLocalDateToUTC,
	}
}

import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { OptionList } from '@components/option-list/option-list'
import { OptionListProps } from '@components/option-list/option-list.types'
import { Paper } from '@components/paper/paper'
import { getHubs } from '@redux/reducers/hubs-reducer'
import { store } from '@redux/store'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { AnalyticsService } from '../../../services/analytics/analytics.service'
import { HubsAPI } from '../../../services/hubs/hubs.api'
import { HubsService } from '../../../services/hubs/hubs.service'
import { Hub } from '../../../services/hubs/hubs.types'
import { AdminItemEditorProps } from '../../../services/utility.types'
import { ValidationService } from '../../../services/validation/validation.service'
import { AdminEditorTitle, AdminEditorTitleProps } from '../components/editor-title'
import { EditHubBodySection } from './edit-hub__body'
import { EditHubGeneralSection } from './edit-hub__general'
import { EditHubPreviewSection } from './edit-hub__preview'

type EditHubSections = 'general' | 'body' | 'preview'

export interface EditHubSectionProps {
	mergedHubProps: Hub | Omit<Hub, 'hubId'>
	updateHubProp: (key: keyof Hub, value: any) => void
}

export function EditHubRoute(props: AdminItemEditorProps<Hub>) {
	const [modifiedHubProps, setModifiedHubProps] = useState<Partial<Hub>>({})
	const [activeSection, setActiveSection] = useState<EditHubSections>('general')

	const originalHubsProps = getOriginalHubProps()
	const mergedHubProps: Hub | Omit<Hub, 'hubId'> = { ...originalHubsProps, ...modifiedHubProps }

	function getOriginalHubProps(): Hub | Omit<Hub, 'hubId'> {
		return props.item ? props.item : newHubProps()
	}

	function updateHubProp(key: keyof Hub, value: any): void {
		const updatedState = { ...modifiedHubProps, [key]: value }
		setModifiedHubProps(updatedState)
	}

	function newHubProps(): Omit<Hub, 'hubId'> {
		return {
			title: '',
			description: '',
			headerBgImage: '',
			body: '',
			sidebar: '',
			stateAgnostic: true,
			licensedStates: [],
			tags: [],
			displayOrder: 0,
			icon: '',
			color: store.getState().theme.colorAccent,
			groupByTag: false,
			slug: '',
			published: true,
			isPublic: false,
		}
	}

	function createHub(): void {
		HubsAPI.createHub(mergedHubProps).then((newHubProps) => {
			AnalyticsService.pushEvent({
				event_category: 'change',
				event_label: 'create_hub',
				value: {
					hub_id: newHubProps.data.hubId,
					hub_title: newHubProps.data.title,
				},
			})

			store.dispatch(getHubs())
			ToastService.create({ type: 'SUCCESS', body: `Hub has been created` })
			if (props.onCreate) {
				props.onCreate(newHubProps.data)
			}
			props.dismissModal()
		})
	}

	function updateHub(): void {
		if ('hubId' in mergedHubProps) {
			const id = mergedHubProps.hubId
			HubsAPI.updateHub(id, modifiedHubProps).then((updatedHubRes) => {
				store.dispatch(getHubs())
				ToastService.create({ type: 'SUCCESS', body: `Hub has been updated` })
				if (props.onUpdate) {
					props.onUpdate(updatedHubRes.data)
				}
				props.dismissModal()
			})
		}
	}

	function getEditorTitleProps(): AdminEditorTitleProps<Hub> {
		return {
			itemLabel: 'Hub',
			item: props.item,
			itemIdKey: 'hubId',
			deleteItemFunction: HubsAPI.deleteHub,
			onDelete: (item) => {
				store.dispatch(getHubs())
				if (props.onDelete) {
					props.onDelete(item)
				}
				props.dismissModal()
			},
		}
	}

	function getSectionOptions(): OptionListProps.Option<EditHubSections>[] {
		return [
			{
				label: 'General',
				value: 'general',
			},
			{
				label: 'Body',
				value: 'body',
			},
			{
				label: 'Preview',
				value: 'preview',
			},
		]
	}

	return (
		<Modal
			maxHeight={1000}
			maxWidth={1400}
			fixedHeight={true}
			className="flex flex-column"
			onClose={props.dismissModal}
		>
			<AdminEditorTitle {...getEditorTitleProps()} />

			<ModalBody className="flex">
				<Paper
					bgColor="primary"
					padding={['all']}
					margins={['right']}
					className="flex flex-column flex-alignItems-start flex-noShrink"
					style={{ width: '250px', position: 'sticky', top: '0px' }}
				>
					<OptionList
						persistent={true}
						value={activeSection}
						options={getSectionOptions()}
						onClick={(option) => {
							setActiveSection(option)
						}}
					/>
				</Paper>

				<div className="flex-fillSpace">
					{activeSection === 'general' && (
						<EditHubGeneralSection mergedHubProps={mergedHubProps} updateHubProp={updateHubProp} />
					)}
					{activeSection === 'body' && (
						<EditHubBodySection mergedHubProps={mergedHubProps} updateHubProp={updateHubProp} />
					)}
					{activeSection === 'preview' && (
						<EditHubPreviewSection mergedHubProps={mergedHubProps} updateHubProp={updateHubProp} />
					)}
				</div>
			</ModalBody>

			<ModalFooter>
				<Button
					variant="outlined"
					size="md"
					primary={false}
					label="Cancel"
					margins={['right']}
					onClick={props.dismissModal}
				/>
				{props.item && (
					<Button
						variant="contained"
						size="md"
						label="Update"
						onClick={() => {
							const validationResults = HubsService.validate(mergedHubProps, store.getState().hubs)
							if (validationResults.isValid) {
								updateHub()
							} else {
								ValidationService.showValidationErrors(validationResults)
							}
						}}
					/>
				)}
				{!props.item && (
					<Button
						variant="contained"
						size="md"
						label="Save"
						onClick={() => {
							const validationResults = HubsService.validate(mergedHubProps, store.getState().hubs)
							if (validationResults.isValid) {
								createHub()
							} else {
								ValidationService.showValidationErrors(validationResults)
							}
						}}
					/>
				)}
			</ModalFooter>
		</Modal>
	)
}

import { useAppDispatch } from '@redux/hooks'
import { getUserProps } from '@redux/reducers/user-reducer'
import { UserProfileState } from 'src/routes/administration/user-profile/state/user-profile__state.types'
import { UserProfileService } from 'src/routes/administration/user-profile/user-profile.service'

import { AnalyticsService } from '../analytics/analytics.service'
import { ToastService } from '../toast/toast.service'
import { UserAPI } from './user.api'
import { EndUserProps, EndUserUpdateProfileProps } from './user.types'

export async function UserUpdateService(
	dispatch: ReturnType<typeof useAppDispatch>,
	mergedUserProps: EndUserProps,
	userProfileState: UserProfileState.LocalState,
	currentUserId: number,
) {
	if (mergedUserProps) {
		if (!UserProfileService().validateLicenses(userProfileState.agentLicenses)) {
			ToastService.create({
				type: 'ERROR',
				body: `Your licenses are not valid`,
			})
			return
		}

		/** Updated licenses */
		await UserProfileService().reconcileLicenseChangesAndCommit(
			mergedUserProps.endUserId,
			mergedUserProps.licenseInformation,
			userProfileState.agentLicenses,
		)
		// changeRequests.push(licenseReq)

		/** Update general user properties */
		const profileDTO: Partial<EndUserProps> = { ...userProfileState.modifiedProps }
		const modifiedProfileDTO: Partial<EndUserUpdateProfileProps> = {
			...userProfileState.modifiedProps,
		}

		if (profileDTO.mlsBoards) {
			modifiedProfileDTO.mlsBoards = userProfileState.modifiedProps.mlsBoards?.map((board) => ({
				mlsBoardId: board.mlsBoardId,
			}))
		}

		await UserAPI.updateUserProperties(mergedUserProps.endUserId, modifiedProfileDTO)

		AnalyticsService.pushEvent({
			event_category: 'change',
			event_label: 'edit_user',
			value: {
				email: mergedUserProps.email,
				first_name: mergedUserProps.firstName,
				last_name: mergedUserProps.lastName,
				user_id: mergedUserProps.endUserId,
			},
		})

		if (currentUserId === userProfileState.endUserId) {
			dispatch(getUserProps({ userId: userProfileState.endUserId }))
			ToastService.create({
				type: 'SUCCESS',
				body: `Profile has been updated`,
			})
		} else {
			ToastService.create({
				type: 'SUCCESS',
				body: `Profile has been updated`,
			})
		}
	}
}

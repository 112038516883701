import { AnalyticsTypes } from './analytics.types'

declare global {
	interface Window {
		dataLayer: any[]
		gtag: null | Function
	}
}

/** https://support.google.com/analytics/answer/9304153?sjid=4121559567465035556-NA#zippy=%2Cadd-the-google-tag-directly-to-your-web-pages%2Cadd-your-tag-using-google-tag-manager */
/** https://www.youtube.com/watch?time_continue=251&v=6upqv3kaIIk&embeds_referring_euri=https%3A%2F%2Fwww.bing.com%2F&embeds_referring_origin=https%3A%2F%2Fwww.bing.com&source_ve_path=MTM5MTE3LDM2ODQyLDM2ODQyLDM2ODQyLDI4NjY2&feature=emb_logo */

export namespace AnalyticsService {
	/**
	 * Pushes an event to Google Analytics
	 */
	export function pushEvent(event: AnalyticsTypes.Event) {
		if (window.gtag) {
			console.log(`Analytics: Pushing event`, event)
			window.gtag('event', event.event_label, event.value)
		} else {
			console.log(`Analytics: Event could not be pushed to GA. GA not initialized`)
		}
	}

	/** https://support.google.com/analytics/answer/12370404?hl=en#zippy=%2Cgoogle-tag-websites */
	export function identifyUser(domainId: number, userProps: AnalyticsTypes.User): void {
		if (window.gtag) {
			window.gtag('set', 'user_properties', {
				first_name: userProps.firstName,
				last_name: userProps.lastName,
				email: userProps.email,
				end_user_id: userProps.endUserId,
			})
			console.log(`Analytics: User is identified`, userProps)
		}
	}

	/**
	 * Initialize a Google Analytics Tracking ID to the application. Once a tracking ID has been initialized, the application
	 * will automatically send page navigation events to all ID's that have been attached. In addition, events pushed via
	 * `pushEvent()` will be sent to this ID.
	 */
	export function initializeGA4TrackingId(props: { gaProperty: string; initializeDebugMode?: boolean }): void {
		if (!window?.gtag) {
			const gaScript = document.createElement('script')
			gaScript.async = true
			gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${props.gaProperty}`
			document.body.append(gaScript)

			window.dataLayer = window.dataLayer || []
			window.gtag = function () {
				window.dataLayer.push(arguments)
			}
			window.gtag('js', new Date())
			console.log(`Google Analytics script added`)
		}

		const gtagConfig: Gtag.ConfigParams & Gtag.CustomParams = {
			send_page_view: true,
			//user_properties: {},
			cookie_domain: 'auto',
		}

		if (props?.initializeDebugMode) {
			gtagConfig.debug_mode = true
		}

		window.gtag('config', props.gaProperty, gtagConfig)

		window.gtag('set', 'cookie_flags', 'SameSite=None;Secure')
		console.log(`Google Analytics: Property ${props.gaProperty} is connected`)
	}

	/** Updates the configuration of a tag
	 * More info here: https://developers.google.com/tag-platform/gtagjs/reference
	 */
	export function updateConfig(props: {
		gaProperty: string
		config: Gtag.ConfigParams | Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams
	}): void {
		if (!window.gtag) {
			throw new Error(`Cannot establish cross domain link`)
		}
		window.gtag('config', props.gaProperty, { ...props.config })
	}
}

import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { RichTextEditor } from '@components/rich-text-editor/rich-text-editor'
import { StateSelectorDropdown } from '@components/state-selector-dropdown/state-selector-dropdown'
import { TagSelectorDropdown } from '@components/tag-selector-dropdown/tag-selector-dropdown'
import { TextInput } from '@components/text-input/text-input'
import { store } from '@redux/store'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { AnalyticsService } from '../../../services/analytics/analytics.service'
import { FAQAPI } from '../../../services/faq/faq.api'
import { FAQService } from '../../../services/faq/faq.service'
import { FAQ } from '../../../services/faq/faq.types'
import { LicensedState } from '../../../services/licensed-states/licenses-states.types'
import { AdminItemEditorProps } from '../../../services/utility.types'
import { ValidationService } from '../../../services/validation/validation.service'
import { AdminEditorTitle, AdminEditorTitleProps } from '../components/editor-title'

export function EditFAQRoute(props: AdminItemEditorProps<FAQ> & { licensedStates?: LicensedState[] }) {
	const [modifiedFAQProps, setModifiedFAQProps] = useState<Partial<FAQ>>({})
	const mergedFAQProps: FAQ | Omit<FAQ, 'faqId'> = _.merge(getOriginalFAQProps(), modifiedFAQProps)

	function getOriginalFAQProps(): FAQ | Omit<FAQ, 'faqId'> {
		return props.item ? props.item : newFAQProps()
	}

	function updateFAQProp(key: keyof FAQ, value: any): void {
		const updatedState = { ...modifiedFAQProps, [key]: value }
		setModifiedFAQProps(updatedState)
	}
	useEffect(() => {
		console.log('modifiedFAQProps changed:', modifiedFAQProps)
		console.log('mergedFAQProps changed:', mergedFAQProps)
		console.log('State: ', mergedFAQProps.stateAgnostic)
	}, [modifiedFAQProps, mergedFAQProps])

	function newFAQProps(): Omit<FAQ, 'faqId'> {
		return {
			question: '',
			answer: '',
			tags: [],
			licensedStates: [],
			stateAgnostic: true,
		}
	}

	function createFAQ(): void {
		FAQAPI.createFAQ(mergedFAQProps).then((newFAQRes) => {
			AnalyticsService.pushEvent({
				event_category: 'change',
				event_label: 'create_faq',
				value: {
					faq_id: newFAQRes.data.faqId,
					faq_title: newFAQRes.data.question,
				},
			})

			ToastService.create({ type: 'SUCCESS', body: `FAQ has been created` })
			if (props.onCreate) {
				props.onCreate(newFAQRes.data)
			}
			props.dismissModal()
		})
	}

	function updateFAQ(): void {
		if ('faqId' in mergedFAQProps) {
			const faqId = mergedFAQProps.faqId
			FAQAPI.updateFAQ(faqId, modifiedFAQProps).then((updatedFAQRes) => {
				ToastService.create({ type: 'SUCCESS', body: `FAQ has been updated` })
				if (props.onUpdate) {
					props.onUpdate(updatedFAQRes.data)
				}
				props.dismissModal()
			})
		}
	}

	function getEditorTitleProps(): AdminEditorTitleProps<FAQ> {
		return {
			itemLabel: 'FAQ',
			item: props.item,
			itemIdKey: 'faqId',
			deleteItemFunction: FAQAPI.deleteFAQ,
			onDelete: (item) => {
				if (props.onDelete) {
					props.onDelete(item)
				}
				props.dismissModal()
			},
		}
	}

	return (
		<Modal
			maxWidth={1000}
			maxHeight={800}
			fixedHeight={true}
			className="flex flex-column"
			onClose={props.dismissModal}
		>
			<>
				<AdminEditorTitle {...getEditorTitleProps()} />

				<ModalBody>
					<TextInput
						label="Question"
						dataType="text"
						value={mergedFAQProps.question}
						width="100%"
						margins={['bottom']}
						onChange={(updatedValue) => {
							updateFAQProp('question', updatedValue)
						}}
						validation={{
							isValid: (value) => {
								return {
									isValid: value.length > 0,
									showValidationFlag: true,
								}
							},
							message: `Cannot be empty`,
						}}
					/>

					<strong className="mb-5">Answer</strong>
					<RichTextEditor
						className="mb-20"
						value={mergedFAQProps.answer}
						rows={10}
						onChange={(updatedValue) => {
							updateFAQProp('answer', updatedValue)
						}}
						validation={{
							isValid: (value) => {
								return {
									isValid: value.length > 0,
									showValidationFlag: true,
								}
							},
							message: `Cannot be empty`,
						}}
					/>

					<div className="mb-20 flex flex-column">
						<strong className="mb-5">Tags</strong>
						<TagSelectorDropdown
							selectedTags={mergedFAQProps.tags}
							options={store.getState().tags}
							onSelect={(selectedTags) => {
								updateFAQProp('tags', selectedTags)
							}}
						/>
					</div>

					<div className="mb-20 flex flex-column">
						<strong className="mb-5">Applicable States</strong>
						<Checkbox
							checked={mergedFAQProps.stateAgnostic}
							onChange={(checked) => {
								updateFAQProp('stateAgnostic', checked)
							}}
							style={{ marginBottom: '8px' }}
						>
							This is applicable to users in all states
						</Checkbox>
						<StateSelectorDropdown
							multiselect={true}
							width={`100%`}
							selectedStates={mergedFAQProps.licensedStates || []}
							options={store.getState().licensedStates}
							onSelect={(selectedStates) => {
								updateFAQProp('licensedStates', selectedStates)
							}}
							disabled={mergedFAQProps.stateAgnostic}
						/>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button
						variant="outlined"
						size="md"
						primary={false}
						label="Cancel"
						margins={['right']}
						onClick={props.dismissModal}
					/>
					{props.item && (
						<Button
							variant="contained"
							size="md"
							label="Update"
							onClick={() => {
								const validationResults = FAQService.validate(mergedFAQProps)
								if (validationResults.isValid) {
									updateFAQ()
								} else {
									ValidationService.showValidationErrors(validationResults)
								}
							}}
						/>
					)}
					{!props.item && (
						<Button
							variant="contained"
							size="md"
							label="Create"
							onClick={() => {
								const validationResults = FAQService.validate(mergedFAQProps)
								if (validationResults.isValid) {
									createFAQ()
								} else {
									ValidationService.showValidationErrors(validationResults)
								}
							}}
						/>
					)}
				</ModalFooter>
			</>
		</Modal>
	)
}
